import {
  SET_ACTIVE_FILTER,
  SET_FILTER_VALUE,
  SET_DYNAMIC_FILTER_VALUE,
  SET_YEAR_OPTIONS_WERE_LOADED,
  GET_FILTERING_OPTIONS_REQUEST,
  GET_FILTERING_OPTIONS_SUCCESS,
  GET_FILTERING_OPTIONS_FAILURE,
  GET_FILTERING_OPTIONS_DYNAMIC_REQUEST,
  GET_FILTERING_OPTIONS_DYNAMIC_SUCCESS,
  GET_FILTERING_OPTIONS_DYNAMIC_FAILURE,
  CLEAR_FILTERS_IF_NEEDED,
} from './filters.actions';

import { CLEAR_STORE } from '../../../../core/user/user.actions';
import { REPLACE_ALL_FILTER_VALUES } from './replaceFilterValues.action';

import { FILTERS } from '../../constants/filters.constants';

export const initialState = {
  activeFilter: null,
  areFilteringOptionsLoading: false,
  areFilteringOptionsDynamicLoading: false,
  yearOptionsWereLoaded: false,
  filteringOptions: {
    date: [],
    transaction_id: [],
    buyer: [],
    supplier: [],
    [FILTERS.YEAR]: null,
    [FILTERS.COUNTRY]: null,
  },
  filterValues: {
    [FILTERS.YEAR]: null,
    // TODO: revert dev default to null
    [FILTERS.COUNTRY]: 'Argentina',
  },
};

export function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_FILTER: {
      const { activeFilter } = action.payload;
      return {
        ...state,
        activeFilter,
      };
    }
    case SET_FILTER_VALUE: {
      const { filterName, filterValue, addToCurrent } = action.payload;
      const oldValue = state.filterValues[filterName];
      const newValue = (addToCurrent && oldValue)
        ? Array.from(new Set([...state.filterValues[filterName], ...filterValue]))
        : filterValue;

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [filterName]: newValue,
        },
      };
    }
    case SET_DYNAMIC_FILTER_VALUE: {
      const { filterName, searchQuery } = action.payload;
      return {
        ...state,
        dynamicField: { filterName, searchQuery },
      };
    }
    case SET_YEAR_OPTIONS_WERE_LOADED: {
      return {
        ...state,
        yearOptionsWereLoaded: action.payload,
      };
    }
    case GET_FILTERING_OPTIONS_REQUEST: {
      return {
        ...state,
        areFilteringOptionsLoading: true,
      };
    }
    case GET_FILTERING_OPTIONS_SUCCESS: {
      const { filteringOptions } = action.payload;

      return {
        ...state,
        areFilteringOptionsLoading: false,
        yearOptionsWereLoaded: true,
        filteringOptions,
        filterValues: {
          ...state.filterValues,
        },
      };
    }
    case GET_FILTERING_OPTIONS_FAILURE: {
      return {
        ...state,
        areFilteringOptionsDynamicLoading: false,
      };
    }
    case GET_FILTERING_OPTIONS_DYNAMIC_REQUEST: {
      return {
        ...state,
        areFilteringOptionsDynamicLoading: true,
      };
    }
    case GET_FILTERING_OPTIONS_DYNAMIC_SUCCESS: {
      const { filteringOptions } = action.payload;

      return {
        ...state,
        areFilteringOptionsDynamicLoading: false,
        filteringOptions: {
          ...state.filteringOptions,
          ...filteringOptions,
        },
      };
    }
    case GET_FILTERING_OPTIONS_DYNAMIC_FAILURE: {
      return {
        ...state,
        areFilteringOptionsLoading: false,
      };
    }
    case CLEAR_FILTERS_IF_NEEDED: {
      const { filtersToClear } = action.payload;

      const prepareEmptyFilters = () => {
        const emptyFilters = {};
        filtersToClear.forEach((filter) => {
          emptyFilters[filter] = [];
        });
        return emptyFilters;
      };

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          ...prepareEmptyFilters(filtersToClear),
        },
      };
    }
    case REPLACE_ALL_FILTER_VALUES: {
      const { filterValues } = action.payload;
      return {
        ...state,
        filterValues,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
